import React from 'react';
import Title from '../components/shared/text/Title';
import styled from 'styled-components';
import SEO from 'src/components/seo';
import  Layout from 'src/layouts/Layout';
import { Section } from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import FadeLink from 'src/components/shared/navigations/FadeLink/FadeLink';
import useLng from "../hooks/useLng";

const StyledSection = styled(Section)`
  @media screen and (max-width: 767px) {
    padding-top: 22rem;
  }
`;

const StyledTitle = styled(Title)`
  margin: 7rem 0;
`;

const NotFoundPage = () => {
    const {t} = useLng()
  return (
    <>
      <Layout className="layout">
        <SEO title="404: Not found" />
        <StyledSection first>
          <div
            style={{
              maxWidth: '113rem',
              margin: '0 auto',
              textAlign: 'center',
            }}
          >
            <StyledTitle>{t("notFound")}</StyledTitle>
            <FadeLink stylish to="/">
                {t("notFound2")}
            </FadeLink>
          </div>
        </StyledSection>
      </Layout>
    </>
  );
};

export default NotFoundPage;
